<template>
  <div id="order-view-additional-cancelled-not-apply">
    <b-button id="order-view-additional-cancelled-not-apply" v-ripple.400 block variant="danger" @click.prevent="confirm = true">
      <feather-icon class="align-middle" icon="CheckIcon" size="20" />
      <span class="ml-25 align-middle"> {{ $t('action.not_apply') }} </span>
    </b-button>

    <b-sidebar id="order-view-additional-cancelled-not-apply-confirm-sidebar" :visible="confirm" @change="confirm = $event">
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">{{ $t('action.not_apply') }} {{ $t('action.edition') }}</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <div class="p-2">
          <h4 class="mb-2">{{ $t('order.cancellation.not_apply_question') }}</h4>
          <b-button
            id="order-view-additional-cancelled-not-apply-confirm-action-yes"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="notApplyCancellation(), hide()"
          >
            {{ $t('common.yes') }}
          </b-button>
          <b-button
            id="order-view-additional-cancelled-not-apply-confirm-action-no"
            type="button"
            variant="outline-secondary"
            block
            class="w-100"
            @click.prevent="hide"
          >
            {{ $t('common.no') }}
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteApplyCancellationPoliciesRequest } from '@/request/globalApi/requests/quoteRequests'

export default {
  name: 'OrderViewAdditionalCancelledNotApply',

  data() {
    return {
      confirm: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected']),
    ...mapFields('quote', ['quoteLoading']),
    ...mapGetters('quote', ['quoteTitle']),
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    notApplyCancellation() {
      this.quoteLoading = true
      patchQuoteApplyCancellationPoliciesRequest(
        this.quoteSelected.quote.id,
        { cancellationPolicyType: null, apply: false, orderUrl: window.location.href },
        this.$store.getters['quote/quoteTitleAndRef'],
      )
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
            this.quoteEditable = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
  },
}
</script>
